<template>
  <b-card no-body class="p-1">
    <b-overlay :show="loading">
      <div class="demo-spacing-0 m-1"
           dir="ltr"
      >
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          فیلتر
          <feather-icon icon="FilterIcon"/>
        </b-button>
      </div>

      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"
          @filter="getData(1, perPage)"
          @reset="getData(1, perPage)"/>

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            @sort-changed="sort($event)"
            no-local-sort
            style="white-space: nowrap; min-height : 235px"
        >

          <template #cell(relatedCoin)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                    :variant="`light-info`"
                    :to="{ name: 'statistics', params: { id: data.item.relatedCoin } }"
                />
              </template>
              <b-link
                  :to="{ name: 'statistics', params: { id: data.item.relatedCoin } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ $coinLabel[data.item.relatedCoin] }}
              </b-link>
              <small class="text-muted">{{ data.item.relatedCoin }} ({{ data.item.site }})</small>
            </b-media>
          </template>

          <template #cell(transactionType)="data">
            <b-badge
                pill
                :variant="'light-'+typeVariant(data.item.transactionType)"
            >
              {{ typeLabel(data.item.transactionType) }}
            </b-badge>
          </template>


          <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount, $decimal[data.item.relatedCoin]) }}
                        </span>
          </template>

          <template #cell(address)="data">
                        <span>
                            {{ data.item.transactionType === 'WITHDRAW' ? data.item.toAddress : data.item.fromAddress }}
                        </span>
          </template>


          <template #cell(transactionId)="data">
            <b-link :href="data.item.viewInExplorer || '#'" target="_blank">
              {{ data.item.transactionId }}
            </b-link>
          </template>

          <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.createdAtDateTime) }}
                        </span>
          </template>


        </b-table>
      </div>

      <!-- pagination -->
      <div
          class="demo-spacing-0 d-flex justify-content-between m-1"
          dir="rtl"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="left"
            @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
              id="perpage"
              v-model="perPage"
              dir="rtl"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="min-width: 85px"
              @input="getData(1,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  BBadge,
  // BAvatar,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';

export default {
  name: 'Withdraw',
  components: {
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    // BAvatar,
    vSelect,
    BLink,
    BOverlay
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 10,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    isActive: false,
    loading: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'relatedCoin',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {label: 'ریپل', value: 'RIPPLE'},
          {label: 'استلار', value: 'STELLAR'},
        ]
      },
      {
        label: 'نام کاربر',
        key: 'customerEmail',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'کیف پول',
        key: 'walletName',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'نوع انتقال',
        key: 'transactionType',
        sortable: true,
        searchType: 'select',
        selectOptions: [
          {label: 'برداشت', value: 'WITHDRAW'},
          {label: 'واریز', value: 'DEPOSIT'},
        ]
      },
      {
        label: 'مقدار انتقال',
        key: 'amount',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'آدرس مبدا / مقصد',
        key: 'address',
        sortable: false,
      },
      {
        label: 'fee charge',
        key: 'feeCharge',
        sortable: false,
      },
      {
        label: 'max fee',
        key: 'maxFee',
        sortable: false,
      },
      {
        label: 'هش لینک',
        key: 'transactionId',
        sortable: false,
      },
      {
        label: 'تاریخ درخواست',
        key: 'createdAtDateTime',
        sortable: true,
        searchType: 'date'
      },
    ],
  }),
  created() {
    this.getData()
  },
  computed: {
    statusVariant() {
      const a = {
        inactive: 'danger',
        active: 'success',
      }
      return e => a[e];
    },
    statusLabel() {
      const a = {
        inactive: 'لغو شده',
        active: 'انجام شده',
      }
      return e => a[e];
    },
    typeVariant() {
      const a = {
        WITHDRAW: 'danger',
        DEPOSIT: 'success',
      }
      return e => a[e];
    },
    typeLabel() {
      const a = {
        WITHDRAW: 'برداشت',
        DEPOSIT: 'واریز',
      }
      return e => a[e];
    }
  },
  methods: {
    async getData(page, perPage = this.perPage) {
      if (this.$route.query.s) {
        return this.getData1(page, perPage)
      }
      this.loading = true
      const queryParams = {
        size: Math.ceil(perPage / this.$urls.length),
        page: page,
        ...this.$route.query
      }
      let items = []
      let rows = 0
      for (const url of this.$urls) {
        const res = await this.$axios.get(
            url[1] + '/wallets/account-transactions',
            {
              params: queryParams
            })
        items.push(...res.data.content.map(e => {
          return {
            ...e,
            site: url[0]
          }
        }))
        rows += res.data.totalElements
      }
      items = items.sort((a, b) => b.createdAtDateTime.localeCompare(a.createdAtDateTime))
      this.items = items
      this.rows = rows
      this.loading = false
    },
    async getData1(page, perPage = this.perPage) {
      this.loading = true
      const queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query
      }
      let items = []
      let rows = 0
      const res = await this.$axios.get(
          this.$urls.find(e => e[0] === this.$route.query.s)[1] + '/wallets/account-transactions',
          {
            params: queryParams
          })
      items.push(...res.data.content.map(e => {
        return {
          ...e,
          site: this.$urls.find(e => e[0] === this.$route.query.s)[0]
        }
      }))
      rows += res.data.totalElements
      this.items = items
      this.rows = rows
      this.loading = false
    },
    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
